import React from 'react';


const LogoLegend = () => {
    return (
        <h2 className="waviy" style={{"fontSize": 0}}>
        <span className="title-word title-sylab-1" style={{i: 1}}>I</span>
        <span className="title-word title-sylab-2" style={{i: 2}}>nno</span>
        <span className="title-word title-sylab-3" style={{i: 3}}>va</span>
        <span className="title-word title-sylab-4" style={{i: 4}}>tion</span>
        <span className="title-word title-sylab-5" style={{i: 5}}>al</span>
        <span className="title-word title-dot" style={{i: 5}}>.</span>
        <span className="title-word title-sylab-6" style={{i: 6}} >ly</span>
        </h2>
    )
}


export default LogoLegend;