import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProjectPropTwo from './itemProp/ProjectPropTwo';
import ProjectData from "../../data/project/ProjectData.json";
import { Link } from 'react-router-dom';

const portfolioData = ProjectData;

const ProjectTwo = () => {
    return (
        <div className="section section-padding-equal bg-color-dark" id="projects">
            <div className="container">
            <SectionTitle 
                subtitle="Where Technology Meets Innovation"
                title="Innovative Solutions, Tailored for Tomorrow"
                description="In an ever-evolving digital landscape, our mission is to deliver solutions that are not just innovative but also practical and forward-looking. At innovational.ly, we blend cutting-edge technology with creative problem-solving to provide tailor-made solutions to your unique challenges. From conceptualization to execution, our focus is on creating value that drives growth and efficiency."
                textAlignment="heading-light-left"
                textColor=""
            />
            <div className="row row-45">
                {portfolioData.slice(12, 16).map((data) => (
                    <div className="col-md-6" key={data.id}>
                        <ProjectPropTwo projectStyle="project-style-2" portfolio={data}/>
                    </div>
                ))}

            </div>
            <div className="more-project-btn disable-component">
                <Link to={process.env.PUBLIC_URL + "/project-grid-two"} className="axil-btn btn-fill-white">Discover More Projects</Link>
            </div>
        </div>
    </div>
    )
}

export default ProjectTwo;