import React, {useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import Select from 'react-select';
// import { Dropdown, Checkbox, Button, Input, Form } from 'semantic-ui-react'; // Assuming Semantic UI for styling;
// import Slider from '@mui/material/Slider';
// import {Slider} from 'react-semantic-ui-range';

const ProjectEstimate = () => {
    const [projectType, setProjectType] = useState('');
    const [environment, setEnvironment] = useState('');
    const [technology, setTechnology] = useState('');
    const [integration, setIntegration] = useState(false);
    const [customization, setCustomization] = useState(5);
    const [scale, setScale] = useState('');
    const [email, setEmail] = useState('');
    const [contactName, setContactName] = useState('');
    const [hours, setHours] = useState(0);
    const [cost, setCost] = useState(0);
    const [hourly, setHourly] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const projectOptions = [
        { label: 'Chatbot', value: 'chatbot' },
        { label: 'NFT Collection', value: 'nft' },
        { label: 'AI Vision Implementation', value: 'ai_vision' },
        { label: 'Something Else', value: 'other' }
    ];
    const baseHours = useMemo(() => ({
        chatbot: 200,
        nft: 600,
        ai_vision: 300,
        other: 250  
    }), []);

    const customTheme = (theme) => ({
        ...theme,
        color: '#D3D3D4',
      });
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? '#2e64e6' : 'transparent',
          borderColor: state.isFocused ? '#94ebeb' : '#525260',
          display: 'flex',
          flexWrap: 'nowrap',
          paddingLeft: "22px",
          borderRadius: "20px",
          border: "1px solid",
          color: '#D3D3D4',
          width: "100%",
          fontSize: "22px",
          fontFamily: "Futura Book",
          height: "60px"
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: '#1d2951',
          color: '#D3D3D4',
          width: '100%'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "22px",
            fontFamily: "Futura Book",
            color: state.isSelected ? 'white' : '#D3D3D4',
            backgroundColor: state.isSelected ? 'l#2e64e6' : '#1d2951', // Change background color for selected options
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: '#D3D3D4',
          })    
    };

    const technologyOptions = [
        { label: 'React + Node', value: 'react_node' },
        { label: 'Python', value: 'python' },
        { label: 'Other Technology', value: 'other' }
    ];

    const scaleOptions = [
        { label: 'Small Scale', value: 'small' },
        { label: 'Large Scale', value: 'large' },
        { label: 'From Scratch', value: 'scratch' },
        { label: 'Ongoing Consultancy/Support', value: 'consultancy' }
    ];
    const calculateEstimate = useCallback(() => {
        let totalHours = baseHours[projectType] || 0;
        if (environment) {
            totalHours += 240;
        }
        if (technology === 'Other Technology') {
            totalHours += 320;
        }
        if (integration) {
            totalHours += 80;
        }
        if (scale === 'Large Scale') {
            totalHours += 320;
        }
        if (customization > 5) { // Assuming slider max is 100
            totalHours += (customization * 2.4);
        }

        const hourlyRate = scale === 'Ongoing Consultancy/Support' || scale === 'From Scratch' ? 125 : 100;
        const estimatedCost = totalHours * hourlyRate;
        setHourly(hourlyRate);
        setHours(totalHours);
        setCost(estimatedCost);
    }, [projectType, environment, technology, integration, customization, scale, baseHours]);

    useEffect(() => {
        console.log('Recalculating estimate');
        calculateEstimate();
    }, [calculateEstimate]);  // Dependencies


    const handleSubmit = async (e) => {
        setIsSubmitting(true);
        e.preventDefault();

        const message = `Thank you for considering Innovational.ly for your project needs. Here's the detailed estimate for the
${projectOptions.find((projectRow) => projectRow.value === projectType).label} project you inquired about:\n
        - Environment: ${environment === 'clientEcosystem' ? 'Within Client\'s Ecosystem' : 'Standalone'}
        - Technology: ${technology}
        - Integration Needs: ${integration ? 'Integration with Client System is Required' : 'No Integration with Client System is Required'}
        - Customization Level: ${customization}% Customization
        - Scale of Engagement: ${scale}
        - Total Estimated Hours: ${Math.floor(hours).toLocaleString()}
        - Estimated Cost: $${Math.floor(cost).toLocaleString()}\n
Please note, these estimates are initial and may vary based on the final project scope and specific requirements.\n
We appreciate your interest in our services. Should you have any questions or need further information, please do not hesitate to contact 
us at estimates@innovational.ly.\n
Warm regards,
Team Innovational.ly`;

        try {
            const estimateResponse = await axios.post('/email-estimate', {
                contactName,
                email,
                message
            });
            if (estimateResponse.status !== 200) {
                setConfirmationMessage('');
                console.error('Failed to submit form:', estimateResponse);
            }
            const data = await estimateResponse.data;
            setConfirmationMessage(data.message);
            setEmail('');
            setContactName('');
        } catch (error) {
            alert('Failed to send estimate. Please try again later. Thank you for your interest!');
        }finally {
            setIsSubmitting(false); // Reset submission indicator
          }
    };



    return (
        <form onSubmit={handleSubmit} className="estimate-form">
            <div className="form-group">
                <label>
                    Project Type
                </label>
                <br></br>
                <Select
                    options={projectOptions}
                    theme={customTheme}
                    placeholder="Select Project Type"
                    clearable={false}
                    styles={customStyles}
                    onChange={e => setProjectType(e.value)}
                />
                {/* <select 
                    value={projectType} 
                    style={{    
                        backgroundColor: "transparent", 
                        paddingLeft: "22px",
                        borderRadius: "20px",
                        border: "1px solid",
                        borderColor: "#525260",
                        width: "100%",
                        height: "60px"
                    }}
                    onChange={e => setProjectType(e.target.value)} 
                >
                        <option 
                            value="chatbot" 
                            // style={{    
                            //     backgroundColor: "transparent", 
                            //     fontSize: "22px",
                            //     fontFamily: "Futura Book",
                            //     color: "#D3D3D4",
                            //     paddingLeft: "22px",
                            //     borderRadius: "20px",
                            //     border: "1px solid",
                            //     borderColor: "#525260",
                            //     width: "100%",
                            //     height: "60px"
                            // }}
                            className='select-option'
                            >Chatbot</option>
                        <option value="nft">NFT Collection</option>
                        <option value="ai_vision">AI Vision Implementation</option>
                </select> */}
            </div>

            
            <div className="form-group">
                <label>
                    Solution Environment is Client's Ecosystem
                </label>
                <br></br>
                <div className="toggle-switch">
                    <input
                        className="checkbox"
                        type="checkbox"
                        name="environmentSwitch"
                        id="environmentSwitch"
                        checked={environment}
                        onChange={e => setEnvironment(e.target.checked)}
                    /> 
                    <label className="label" htmlFor="environmentSwitch">
                        <span className="inner" />
                        <span className="switch" />
                    </label>
                </div>
            </div>


            <div className="form-group">
                <label>
                    Technology Stack
                    {/* <select value={technology} onChange={e => setTechnology(e.target.value)}>
                        <option value="react_node">React + Node / Python</option>
                        <option value="mobile">Something Else (e.g. Mobile)</option>
                    </select> */}
                </label>
                <Select
                    options={technologyOptions}
                    theme={customTheme}
                    placeholder="Select Technology Stack"
                    clearable={false}
                    styles={customStyles}
                    onChange={e => setTechnology(e.label)}
                    
                />
            </div>

            <div className="form-group">
                <label>
                    Integration with Systems/Data
                </label>
                <br></br>
                <div className="toggle-switch">
                    <input
                        className="checkbox"
                        type="checkbox"
                        id="integrationSwitch"
                        checked={integration}
                        onChange={e => setIntegration(e.target.checked)}
                    /> 
                    <label className="label" htmlFor="integrationSwitch">
                            <span className="inner" />
                            <span className="switch" />
                    </label>
                </div>
            </div>

            <div className="form-group">
                <label>
                    Level of Customization
                </label>
                <br></br>
                <input
                    type="range"
                    min="0"
                    max="100"
                    value={customization}
                    onChange={e => setCustomization(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>
                    Project Scale
                </label>
                <br></br>
                <Select
                    options={scaleOptions}
                    theme={customTheme}
                    placeholder="Select Project Scale"
                    clearable={false}
                    styles={customStyles}
                    onChange={e => setScale(e.label)}
                />
            </div>
            {/* <select 
                    style={{    
                        backgroundColor: "transparent", 
                        paddingLeft: "22px",
                        borderRadius: "20px",
                        border: "1px solid",
                        borderColor: "#525260",
                        width: "100%",
                        height: "60px"
                    }}
                    value={projectType} 
                    onChange={e => setScale(e.target.value)}
                >
                    <option transparent value="small">Small Scale</option>
                    <option className='form-element' value="large">Large Scale</option>
                    <option value="scratch">From Scratch</option>
                    <option value="consultancy">Ongoing Consultancy/Support</option>
            </select> */}
            <div className="form-group">
                <label>
                    Your Email
                </label>
                <br></br>
                <input
                    type="email"
                    value={email}
                    placeholder="youremail@mail.com" 
                    onChange={e => setEmail(e.target.value)}
                    required
                    disabled={isSubmitting}
                />
            </div>
            <div className="form-group">
                <label>
                    Your Name
                </label>
                <br></br>
                <input
                    type="text"
                    value={contactName}
                    onChange={e => setContactName(e.target.value)}
                    placeholder="Enter your name"
                    disabled={isSubmitting}
                />
            </div>

            <button 
                type="submit" 
                className="axil-btn btn-fill-primary w-auto btn-primary btn-estimate" 
                // disabled={!/\S+@\S+\.\S+/.test(email)}
                disabled={isSubmitting}
            >Send Estimate</button>
            <div>
                {scale || projectType || environment || technology || integration || customization > 5 ? 
                (
                    scale === 'From Scratch' || scale === 'Ongoing Consultancy/Support' ? (
                        <div>
                        <h4>Monthly Estimated Cost: ${hourly}</h4>
                        <p>This represents the monthly investment required for ongoing consultancy or comprehensive project development from scratch, ensuring alignment with strategic business objectives and operational excellence.</p>
                        </div>
                    ) : (
                        <div>
                        <h4>Estimated Hours: {Math.floor(hours).toLocaleString()}</h4>
                        <h4>Estimated Cost: ${Math.floor(cost).toLocaleString()}</h4>
                        </div>
                    )
                ) : (<div></div>)
                }
            </div>
            <div className="form-group">
            {confirmationMessage 
                && 
                    <p id="estimate-confirmation">
                        Estimate sent to your email ✅ 
                        <br></br>Thank you for your interest! 
                    </p>
            }
        </div>
        </form>
        
    );
};

export default ProjectEstimate;