import React from 'react';

const Data = [
    {
        serviceTitle: "Creative Innovation",
        image: "/images/brand/brand-1.png"
    },
    {
        serviceTitle: "AI Engineering",
        image: "/images/brand/brand-2.png"
    },
    {
        serviceTitle: "Full Stack Development",
        image: "/images/brand/brand-3.png"
    },
    {
        serviceTitle: "Machine Learning",
        image: "/images/brand/brand-4.png"
    },
    {
        serviceTitle: "Deep Learning",
        image: "/images/brand/brand-5.png"
    },
    {
        serviceTitle: "Product Management",
        image: "/images/brand/brand-6.png"
    },
    {
        serviceTitle: "Blockchain",
        image: "/images/brand/brand-7.png"
    },
    {
        serviceTitle: "Data Science",
        image: "/images/brand/brand-8.png"
    }
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid project-style-2">
                        <div className="thumbnail tile-gradient">
                            <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
                        </div>
                        <div className="content">
                            <span className="subtitle hatched-title">
                                {data.serviceTitle}
                            </span>
                        </div>
                    </div>

                </div>
            ))}
        </>
    )
}

export default BrandItem;