import React from 'react';
import FooterTwo from '../common/footer/FooterTwo';
// import HeaderOne from '../common/header/HeaderOne';
import HeaderThree from '../common/header/HeaderThree';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SEO from '../common/SEO';

const TermsOfService = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <main className="main-wrapper">
                <HeaderThree />
                <BreadCrumbOne 
                title="Terms of Service"
                page="Terms Of Service"
                />
                <div className="section-padding privacy-policy-area bg-color-dark">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <h4>Introduction</h4>
                                <p>
                                    These Terms of Service govern your use of the Innovational.ly website and services provided by us. By accessing our 
                                    website or engaging our services, you agree to abide by these terms.
                                </p>
                                <h4>Intellectual Property</h4>
                                <p>
                                    All content and functionality on the Innovational.ly website, including text, graphics, logos, icons, images, audio clips, 
                                    digital downloads, and software, are the exclusive property of Innovational.ly or its content suppliers and are protected 
                                    by international copyright and intellectual property laws. The website is available for informational and commercial use by 
                                    our clients and should not be used for any other purpose without our express written consent.
                                </p>
                                <h4>Estimates</h4>
                                <p>
                                    Project estimates provided by Innovational.ly are not final quotes and are subject to change based on project scope and 
                                    specific requirements. Additional research may be required for certain technologies.
                                </p>
                                <h4>Travel Costs</h4>
                                <p>
                                    For projects requiring travel, costs will be discussed and agreed upon between Innovational.ly and the client before 
                                    commencement of travel.
                                </p>
                                <h4>Scope Changes</h4>
                                <p>
                                    Any changes in project scope must be mutually discussed and agreed upon between Innovational.ly and the client. 
                                    Such changes may impact project timelines and costs.
                                </p>
                                <h4>Resource Readiness</h4>
                                <p>
                                    Project estimates assume readiness of necessary resources including infrastructure, personnel, system access, and documentation. 
                                    The estimate timeframe begins upon procurement and readiness of these resources.
                                </p>
                                <h4>Limitation of Liability</h4>
                                <p>
                                    Innovational.ly shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use or 
                                    inability to use our services, even if advised of the possibility of such damages.
                                </p>
                                <h4>Governing Law</h4>
                                <p>
                                    These Terms and Conditions are governed by and construed in accordance with the laws of the State of Arizona in the United States.
                                </p>
                                <h4>Contact Us</h4>
                                <p>
                                    If you have any questions about these Terms and Conditions or our Privacy Policy, please contact us at contact@innovational.ly.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterTwo />
            </main>
        </>
    )
}

export default TermsOfService;