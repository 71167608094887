import React from 'react';
import FooterTwo from '../common/footer/FooterTwo';
// import HeaderOne from '../common/header/HeaderOne';
import HeaderThree from '../common/header/HeaderThree';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderThree />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area bg-color-dark">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This privacy policy was published on August 15th, 2024.</h5>
                                </div>
                                <h4>Introduction</h4>
                                <p>
                                    At Innovational.ly, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, 
                                    use, and safeguard your information when you visit our website or engage with our services.
                                </p>
                                <h4>Information We Collect</h4>
                                <p>
                                    We do not collect personal information or use cookies for tracking purposes. Any personal information such as name
                                    and email address is collected optionally and only when provided by you voluntarily, for purposes such as contacting 
                                    you or providing project estimates. 
                                </p>
                                <h4>Use of Information</h4>
                                <p>
                                    Any information provided to us is used solely for the intended purposes stated at the time of collection. We do not share
                                    or sell your information to third parties.
                                </p>
                                <h4>Security</h4>
                                <p>
                                    We implement reasonable security measures to protect your information from unauthorized access or disclosure.
                                </p>
                                <h4>Changes to this Privacy Policy</h4>
                                <p>
                                    We reserve the right to update this Privacy Policy periodically. Any changes will be posted on this page.
                                </p>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterTwo />
            </main>
        </>
    )
}

export default PrivacyPolicy;