import React from 'react';
import {Link, useParams} from 'react-router-dom';
// import FooterOne from '../common/footer/FooterOne';
import FooterTwo from '../common/footer/FooterTwo';
// import HeaderOne from '../common/header/HeaderOne';
import HeaderThree from '../common/header/HeaderThree';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import VideoOne from '../component/video/VideoOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import ChatWithAurora from '../component/chatbot/ChatWithAurora';
import VideoAnalyzer from '../component/aivision/VideoAnalyzer';
// import { Helmet } from 'react-helmet';

const allProjectData = ProjectData;

const ProjectDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;

    const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    const detailsProject = getProjectData[0];


    return (
        <>
        {/* <Helmet>
            <title>{detailsProject.og_title}</title>
            <meta property="og:title" content={detailsProject.og_title} />
            <meta property="og:description" content={detailsProject.og_description} />
            <meta property="og:image" content={detailsProject.og_image} />
            <meta property="og:url" content={detailsProject.og_url} />
        </Helmet> */}
        <SEO title="Project Details"/>
        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderThree />
            <BcrumbBannerTwo 
            title={detailsProject.title}
            paragraph ={detailsProject.excerpt}
            mainThumb={detailsProject.image}
            bubbleBig={detailsProject.bubble_big}
            bubbleSmall={detailsProject.bubble_small}
            />
            <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">
                                    {detailsProject.category.map((cat, i) => (
                                            <span key={i}>{cat}</span> 
                                        )
                                    )}
                                </span>
                                <h3 className="title">{detailsProject.title}</h3>
                            </div>
                            {detailsProject.body.map((para, index) => (
                                <p key={index} dangerouslySetInnerHTML={{__html: para}} className="details-paragraph details-margin"></p>
                            ))}
                            {/* Conditionally render Aithos NFT ribbon based on project id */}
                            {detailsProject.id === "Aithos NFT Collection" && <Link to="https://aithoscollection.com/" className="axil-btn btn-fill-primary aithos-btn">Get Yours</Link>}
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="why-choose-us">
                                <div className="section-heading heading-left">
                                    <h3 className="title">{detailsProject.tech_subtitle}</h3>
                                    <p>{detailsProject.tech_body}</p>
                                </div>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><FaCompress /> {detailsProject.design}</Accordion.Header>
                                        <Accordion.Body className="details-paragraph">
                                        {detailsProject.design_body}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FaCode /> {detailsProject.impact}</Accordion.Header>
                                        <Accordion.Body className="details-paragraph">
                                        {detailsProject.impact_body}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><FaGlobe /> {detailsProject.solution}</Accordion.Header>
                                        <Accordion.Body className="details-paragraph">
                                        {detailsProject.solution_body}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    {/* Conditionally render ChatWithAurora based on project id */}
                    {detailsProject.id === "Aurora" && <ChatWithAurora />}
                    {/* Conditionally render VideoAnalyzer based on project id */}
                    {detailsProject.id === "AI Vision" && <VideoAnalyzer />}
                </div>
            </section>
            <VideoOne />
            <CtaLayoutOne />
            <FooterTwo />
        </main>
        </>
    )
}

export default ProjectDetails;