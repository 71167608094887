import React from 'react';
import { Link } from 'react-router-dom';



const FooterTwo = () => {

    return (
            <footer className="footer-area footer-dark">
                <div className="footer-logo">
                    <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="logo" />
                </div>
                <div className="footer-content">
                    <div className="footer-bottom">
                    <div className="row legal">
                        <div className="col-md-6">
                        <div className="footer-copyright">
                                <span className="copyright-text">© Copyright {new Date().getFullYear()}.&nbsp;&nbsp; <a href="https://innovational.ly/"> innovational.ly</a>.</span>
                        </div>  
                        </div>
                        <div className="col-md-6">
                        <div className="footer-bottom-link">
                            <ul className="list-unstyled">
                                <li ><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                <li ><Link to={process.env.PUBLIC_URL + "/terms-service"}>Terms of Service</Link></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
    )
}

export default FooterTwo;