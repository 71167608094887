import React, {useRef, useState} from 'react';
// import emailjs from '@emailjs/browser';
// import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

// const Result = () => {
//     return (
//         <Alert variant="success" className="success-msg">
//         Your Message has been successfully sent.
//         </Alert>
//     )
// }

const FormThree = () => {

    const form = useRef();

    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setConfirmationMessage('');

        const emailData = JSON.stringify({ contactName, email, message });
        try {
            const response = await axios.post('/email',emailData, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });
            // const data = await response.json();

            if (response.status !== 200) {
                setConfirmationMessage('We apologize for the inconvenience. There was an issue processing your request. Please try again later.');
                console.error('Failed to submit form:', response);
            }
            const data = await response.data;
            setConfirmationMessage(data.message);
            setEmail('');
            setContactName('');
            setMessage('');

        } catch (error) {
            console.error('Failed calling /email endpoint', error);
            alert('Failed to send email. Please try again later. Thank you for your interest!');
        } finally {
            setIsSubmitting(false); // Reset submission indicator
          }
      };
        // emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form., 'WLENsTkBytC0yvItS')
        //   .then((result) => {
        //       console.log(result.text);
        //   }, (error) => {
        //       console.log(error.text);
        //   });
        //   form.current.reset();
        //   showresult(true);
      

        // setTimeout(() => {
        //     showresult(false);
        // }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <div className="form-group">
            <label>Your Name</label>
            <input 
            type="text" 
            className="form-control" 
            name="contact-name" 
            placeholder="Enter your name" 
            value={contactName} 
            disabled={isSubmitting}
            onChange={(e) => setContactName(e.target.value)}
            required />
        </div>
        <div className="form-group">
            <label>Your Email Address</label>
            <input type="email" 
            className="form-control" 
            name="contact-email" 
            placeholder="youremail@mail.com" 
            value={email} 
            disabled={isSubmitting}
            onChange={(e) => setEmail(e.target.value)}
            required />
        </div>
        <div className="form-group mb--40">
            <label>Your Message</label>
            <textarea 
            name="contact-message" 
            id="contact-message" 
            placeholder = "What can we help you with?" 
            className="form-control textarea" 
            cols="30" 
            rows="6" 
            value={message} 
            disabled={isSubmitting}
            onChange={(e) => setMessage(e.target.value)}
            required>

            </textarea>
        </div>
        <div className="form-group text-end">
            <button 
            disabled={isSubmitting}
            type="submit" 
            className="axil-btn btn-fill-primary w-auto btn-primary" 
            name="submit-btn">SEND
            </button>
        </div>
        <div className="form-group">
            {confirmationMessage && <p id="confirmation">{confirmationMessage}</p>}
        </div>

    </form>
    
    )
}

export default FormThree;