import React from 'react';
import HeaderThree from '../common/header/HeaderThree';
import SEO from '../common/SEO';
import BannerThree from '../component/banner/BannerThree';
import ProjectTwo from '../component/project/ProjectTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BrandOne from '../component/brand/BrandOne';
//import ProjectThree from '../component/project/ProjectThree';
import TestimonialTwo from '../component/testimonial/TestimonialTwo';
import FooterTwo from '../common/footer/FooterTwo';
import FormThree from '../component/contact/FormThree';

const InnovationalHome = () => {

    return (
        <>
        <SEO title="Cutting Edge Tech"/>
        {/* <ColorSwitcher /> */}
        <main className="main-wrapper" id="home">
            <HeaderThree />
            <BannerThree />
            <ProjectTwo />
            {/*<ProjectThree />*/}

            <BrandOne />
            <TestimonialTwo />

            <div id ="contact" className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-address mb--30">
                            <SectionTitle 
                                subtitle="We're Ready to Help Transform Your Vision into Reality"
                                title="Get In Touch"
                                description="Reach out to discuss your innovative projects and find out how we can support your business growth. Our team is here to provide you with tailored solutions and expert advice. Let’s create something amazing together!"
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            {/* <div>
                                <p>
                                Reach out to discuss your innovative projects and find out how we can support your business growth. Our team is here to provide you with tailored solutions and expert advice. Let’s create something amazing together!
                                </p>
                            </div> */}

                            {/* <div className="address-list">
                                <div className="address">
                                <h6 className="title">Mail</h6>
                                <p>example@gmail.com</p>
                                </div>
                                <div className="address">
                                <h6 className="title">Address</h6>
                                <p>Warnwe Park Streetperrine, FL 33157 New York City</p>
                                </div>
                                <div className="address">
                                <h6 className="title">Phone</h6>
                                <p>+0123456789</p>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form-box mb--30">
                                {/* <h3 className="title">Describe your project</h3> */}
                                <FormThree />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </main>
        </>
    )
}

export default InnovationalHome;

