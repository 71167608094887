import React from 'react';
import { Link } from 'react-router-dom';
// import { AnimationOnScroll } from 'react-animation-on-scroll';
// import Tilty from 'react-tilty';         DEPRECATED, using Tilt instead
import Tilt from 'react-parallax-tilt';
// import ScrollAnimation from 'react-animate-on-scroll';


const BannerThree = () => {

    return (
            <div className="banner banner-style-3">
            <div className="container">
                <div className="row">
                <div className="col-lg-6">
                    <div className="banner-content slide-up">

                        <span className="subtitle">CRAFTING A HIGH-TECH, DEEPLY HUMAN TOMORROW</span>
     
                  
                        {/* <AnimationOnScroll animateIn="slideInUp" duration={1} animateOnce={true} delay={100}>
                            <span className="subtitle">CRAFTING A HIGH-TECH, DEEPLY HUMAN TOMORROW</span>
                        </AnimationOnScroll>
                        */}
                        <h1 className="title slide-up">
                            <ul>
                                <li>Lead Forward</li>
                                <li>Innovate</li>
                                <li>Elevate</li>
                            </ul>
                        </h1>
                      
                        
                        {/* <ScrollAnimation  animateIn="slideInUp" duration={1} animateOnce={true} delay={200}> */}
                            <div className="btn-group slide-up">
                                <a href="/#projects" className="axil-btn btn-fill-primary btn-large">
                                    <div className='href_content'>
                                        <div className='href_item'>
                                            See Innovation in Action
                                        </div>
                                        <div className=' href_item'>
                                            <span className='arrow'>
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <Link to={process.env.PUBLIC_URL + "/about-us"} className="about-btn">About Us</Link>
                            </div>
                        {/* </ScrollAnimation> */}
                        
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="banner-thumbnail slide-up">
                    <div className="large-thumb">
                        {/* <ScrollAnimation animateIn="slideInUp" duration={1} animateOnce={true} delay={200}> */}
                            {/* <Tilty perspective={2000} reset={false}>
                                <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-7.png"} alt="Shape" />
                            </Tilty> */}
                            <Tilt tiltMaxAngleX={20} tiltMaxAngleY={20} perspective={2000}>
                                <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-7.png"} alt="Shape" />
                            </Tilt>
                        {/* </ScrollAnimation> */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-20">
                <li className="shape shape-1">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-32_tech.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-33_tech.png"} alt="Bubble" />
                </li>
                <li className="shape shape-4">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-14_tech.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-4_tech.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-30_tech.png"} alt="Bubble" />
                </li>
                <li className="shape shape-7">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-31_tech.png"} alt="Bubble" />
                </li>
                <li className="shape shape-8 marque-images" />
            </ul>
        </div>

    )
}

export default BannerThree;
