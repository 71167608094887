import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/badge.png"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Commitment to Excellence</span>
                        <h2> Why Choose Us?</h2>  
                        <p>At Innovational.ly, we don't just deliver solutions; we revolutionize how businesses interact with technology. Our commitment to innovation is matched by our dedication to your success, ensuring that each project delivers lasting value.</p>
                        <Link to="#" className="axil-btn btn-large btn-fill-primary disable-component">Our Team</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;