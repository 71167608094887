import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: "Business Analysis",
        title: "Business Analysis",
        para: "Analyzing needs, managing requirements and crafting functional specifications."
    },
    {
        id: "Innovation Leadership",
        title: "Innovation Leadership",
        para: "Guiding need-centric innovation through ideation, business modeling, prototyping, customer feedback, and transformation."
    },
    {
        id: "Product Strategy",
        title: "Product Strategy",
        para: "Collaboratively shaping product vision and roadmap, prioritizing business objectives and managing the product backlog."
    },
    {
        id: "System Architecture",
        title: "System Architecture",
        para: "Facilitating strategic decisions regarding system structure and behavior."
    },
    {
        id: "Solution Engineering",
        title: "Solution Engineering",
        para: "Engineering tailored technological solutions to meet business needs."
    },
    {
        id: "Data & Application Integration",
        title: "Data & Application Integration",
        para: "Integrating multiple data sources and interconnecting applications."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Leverage Our Expertise to Transform Your Organization"
                    title="Empowering Business With Strategic Innovation"
                    description="Our diverse expertise in Business Analysis, Innovation Leadership, Product Strategy, System Architecture, Solution Engineering, and Data & Application Integration can elevate your business operations. With our extensive experience, we'll simplify every step of your journey through technological adaptation and strategic decision-making, ensuring a smooth transition and effective results."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                {/* <h3 className="sl-number">{data.id}</h3> */}
                                <h5 className="title sl-number">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;