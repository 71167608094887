import React, { useState, useEffect, useCallback } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import axios from 'axios';

// Predefined video options
const videos = [
    { id: 1, src: '/videos/car_service_1.mp4', thumbnail: '/images/video/car_service_1_thumb.jpg' },
    { id: 2, src: '/videos/car_service_2.mp4', thumbnail: '/images/video/car_service_2_thumb.jpg' },
    { id: 3, src: '/videos/car_service_3.mp4', thumbnail: '/images/video/car_service_3_thumb.jpg' },
];

const VideoAnalyzer = () => {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [analysisResponse, setAnalysisResponse] = useState({});
    const [videoElement, setVideoElement] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    //const [downscaledImageUrl, setDownscaledImageUrl] = useState(''); // Added state to store the downscaled image URL
    const [loading, setLoading] = useState(false);  // State for loading spinner
    const [showMore, setShowMore] = useState(false);  // Toggle for 'Show More' in Additional Observations
    const [firstFrameAnalyzed, setFirstFrameAnalyzed] = useState(false); // New state to track if first frame is analyzed
    const [popupVisible, setPopupVisible] = useState(false); // Track popup visibility for closing




    // Helper function to downscale an image
    const downscaleImage = (sourceCanvas, targetWidth, targetHeight) => {
        const downscaledCanvas = document.createElement('canvas');
        downscaledCanvas.width = targetWidth;
        downscaledCanvas.height = targetHeight;
        const context = downscaledCanvas.getContext('2d');

        // Apply a smooth scaling method (Lanczos can be implemented or use browser's default smoothing)
        context.imageSmoothingEnabled = true;
        context.imageSmoothingQuality = 'high';  // Browser's default high-quality scaling

        // Draw the original image to the smaller canvas
        context.drawImage(sourceCanvas, 0, 0, targetWidth, targetHeight);

        return downscaledCanvas;
    };

     // #improvement: Capture and analyze the first frame as soon as video starts playing
    const captureFirstFrame = useCallback(() => {
        if (videoElement) {
            setLoading(true);
            console.log("turne on loading... ",loading)
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            
            // #improvement: Downscale the frame to 600x300 pixels
            const downscaledCanvas = downscaleImage(canvas, 600, 300);
            const imageBase64 = downscaledCanvas.toDataURL('image/jpeg').split(',')[1];
            
            // Display the downscaled image (for debugging purposes)
            //setDownscaledImageUrl(downscaledCanvas.toDataURL('image/jpeg')); // Added line to store the downscaled image URL

            axios.post('/analyze-image', { imageBase64 })
                .then(response => {
                    setAnalysisResponse(JSON.parse(response.data.reply));
                    setLoading(false);
                    console.log("turn off loading... ",loading)
                    setFirstFrameAnalyzed(true);  // Set to true after the first frame
                })
                .catch(error => {
                    console.error('Failed to analyze frame:', error);
                    setLoading(false);
                    console.log("turn off loading... ",loading)
                });
        }
    }, [videoElement, loading]);
   
   
   
    // Function to capture a frame every 6 seconds and send it to the server
    const captureFrameAndAnalyze = useCallback(() => {
        if (videoElement && firstFrameAnalyzed) {
            // setLoading(true);
            // console.log("turn on loading 2... ",loading)
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            
            // #improvement: Downscale the frame to 600x300 pixels
            const downscaledCanvas = downscaleImage(canvas, 600, 300);
            const imageBase64 = downscaledCanvas.toDataURL('image/jpeg').split(',')[1];

            // Display the downscaled image (for debugging purposes)
            //setDownscaledImageUrl(downscaledCanvas.toDataURL('image/jpeg')); // Added line to store the downscaled image URL

            // Send frame to server for analysis
            console.log('Payload: ', imageBase64);
            console.log('Payload size (bytes):', new Blob([JSON.stringify(imageBase64)]).size);
            axios.post('/analyze-image', { imageBase64 })
                .then(response => {
                    console.log("response: ",response);
                    console.log("response: ",response.data.reply);
                    console.log("response: ",JSON.parse(response.data.reply));
                    setAnalysisResponse(JSON.parse(response.data.reply));
                    // setLoading(false);
                })
                .catch(error => {
                    console.error('Failed to analyze frame:', error);
                    // setLoading(false);
                });
        }
    }, [videoElement, firstFrameAnalyzed]);

    // Capture a frame every 6 seconds
    useEffect(() => {
        if (isPlaying && !firstFrameAnalyzed) {
            captureFirstFrame(); // Capture the first frame immediately
        }
        if (isPlaying && firstFrameAnalyzed) {
            const interval = setInterval(captureFrameAndAnalyze, 6000);
            return () => clearInterval(interval);
        }
    }, [isPlaying, firstFrameAnalyzed, captureFrameAndAnalyze, captureFirstFrame]);

    const handleVideoSelect = (video) => {
        setSelectedVideo(video);
        setIsPlaying(false);  // Reset playing state
        setAnalysisResponse({});  // Reset analysis data
        //setDownscaledImageUrl('');  // Reset downscaled image display
        setFirstFrameAnalyzed(false); // Reset first frame analysis state
        setPopupVisible(true); // Show popup
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    return (
        <div className="video-analyzer">
            <br></br>
            <h2>Video Analyzer</h2>
            {/* #improvement: Added an introductory paragraph */}
            <h5>
                Choose one of the available videos for analysis. The results will be displayed next to the video once it starts playing.
            </h5>
            <div className="vision-grid">
                {videos.map(video => (
                    <div 
                        key={video.id} 
                        className={`thumbnail ${selectedVideo?.id === video.id ? 'active' : ''}`}
                        onClick={() => handleVideoSelect(video)}
                        >
                        <img
                            src={video.thumbnail}
                            alt="Thumbnail"
                            style={{ cursor: 'pointer' }}
                        />
                        {/*
                        {selectedVideo?.id === video.id && <div className="play-icon-overlay">▶</div>}
                        */}
                    </div>
                ))}
            </div>

            {popupVisible && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        {/* <span className="close-btn" onClick={closePopup}> */}
                            {/* <button class="close-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </button> */}
                        <CloseButton className="close-btn" onClick={closePopup}/>
                        {/* </span> */}
                        <div className="video-container">
                            <video
                                src={selectedVideo?.src}
                                controls
                                ref={setVideoElement}
                                onPlay={() => setIsPlaying(true)}
                                onPause={() => setIsPlaying(false)}
                                width="600"
                            ></video>
                        </div>
                        <div className="analysis-results">
                            <h4>Analysis Results</h4>
                            {loading ? (
                                <div className="loading">Processing Video...</div>
                            ) : (
                                Object.keys(analysisResponse).length > 0 ? (
                                    <>
                                        <p><strong>Car Status:</strong> {analysisResponse.CarStatus || 'N/A'}</p>
                                        <p><strong>Technician Uniform:</strong> {analysisResponse.TechnicianUniformStatus || 'N/A'}</p>
                                        <p><strong>Work Area:</strong> {analysisResponse.WorkAreaStatus || 'N/A'}</p>
                                        <p>
                                            <strong>Additional Observations:</strong> 
                                            {showMore || (analysisResponse.AdditionalObservations && analysisResponse.AdditionalObservations.length <= 100)
                                                ? analysisResponse.AdditionalObservations
                                                : `${analysisResponse.AdditionalObservations.slice(0, 100)}...`}
                                            {analysisResponse.AdditionalObservations?.length > 100 && (
                                                <button onClick={() => setShowMore(!showMore)}>
                                                    {showMore ? 'Show less' : 'Show more'}
                                                </button>
                                            )}
                                        </p>
                                    </>
                                ) : (
                                    <p>No analysis available yet.</p>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* Added section to display the downscaled image for debugging */}
            {/* {downscaledImageUrl && (
                <div style={{ marginTop: '20px' }}>
                    <h4>Extracted and Downscaled Frame</h4>
                    <img src={downscaledImageUrl} alt="Downscaled Frame" />
                </div>
            )} */}


        </div>
    );
};

export default VideoAnalyzer;