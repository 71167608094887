import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BrandItem from './BrandItem';


const BrandOne = () => {
    return (
        <div id = "whatwedo" className="section section-padding-2">
        <div className="container">
            <SectionTitle 
                subtitle="Our Approach to Innovation"
                title="What we do"
                description="At innovational.ly, we harness the power of AI, machine learning, blockchain, web and emerging technologies. Our approach involves deep analysis, bespoke development, continuous feedback and iterative enhancements to deliver innovative solutions that transform industries."
                textAlignment="heading-light-left"
                textColor=""
            />
            <div className='row'>
               <BrandItem />
            </div>
        </div>
        <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
        </ul>
    </div>
    )
}

export default BrandOne;