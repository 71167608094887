import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaBehance } from "react-icons/fa";


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    // Handler to close the menu
    const handleLinkClick = () => {
        offcanvasHide();
    };

    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form disable-component">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form>
                <div className="row ">
                    <div className="col-lg-12 col-xl-12">
                        <ul className="main-navigation list-unstyled">
                            <li><HashLink to={process.env.PUBLIC_URL + "/#home"} onClick={handleLinkClick}>Home</HashLink></li>
                            <li><HashLink to={process.env.PUBLIC_URL + "/#projects"} onClick={handleLinkClick}>Projects</HashLink></li>
                            <li><HashLink to={process.env.PUBLIC_URL + "/#whatwedo"} onClick={handleLinkClick}>What We Do</HashLink></li>
                            <li><Link to={process.env.PUBLIC_URL + "/about-us"} onClick={handleLinkClick}>About Us</Link></li>
                            <li><HashLink to={process.env.PUBLIC_URL + "/#contact"} onClick={handleLinkClick}>Contact Us</HashLink></li>
                        </ul>
                        <br></br>
                        <div className="contact-inner">
                                <h5 className="title">Follow Us on LinkedIn</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li className='disable-component'>
                                            <a href="https://facebook.com/"><FaFacebookF /></a>
                                        </li>

                                        <li className='disable-component'>
                                            <a href="https://twitter.com/"><FaTwitter /></a>
                                        </li>
                                        <li className='disable-component'>
                                            <a href="https://www.behance.net/"><FaBehance /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/innovationally/"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                    </div>
                    {/*
                    <div className="col-lg-7 col-xl-6 disable-component">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Theodore Lowe, Ap #867-859 <br /> Sit Rd, Azusa New York</p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <a href="tel:8884562790" className="tel"><FaPhone /> (888) 456-2790</a>
                                    <a href="tel:12125553333" className="tel"><FaFax /> (121) 255-53333</a>
                                </address>
                            </div>

                        </div> 
                    </div>*/}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
